<template>
  <div class="picture-upload">
    <label
      for="picture-upload-btn"
      class="picture-upload-btn"
      v-if="!isSlidePreview"
    >
      {{ $t("pages.editor.uploadPhoto") }}
    </label>
    <input
      id="picture-upload-btn"
      type="file"
      ref="file"
      @change="loadImage"
      accept=".png,.jpg,.jpeg"
    />
    <div class="picture-upload__feedback" v-if="feedback">
      {{ feedback }}
    </div>
    <Modal
      class="picture-upload__modal"
      v-if="showModal"
      @close="onClose"
      :heading="$t('pages.editor.pictureModal.title')"
    >
      <div class="picture-upload__content" slot="modal-content">
        <div class="picture-upload__field">
          <vue-croppie
            v-if="orientation === '0'"
            class="picture-upload__cropper"
            ref="croppieRef"
            :enableOrientation="true"
            :enableResize="false"
            :enforceBoundary="false"
            :boundary="landscapeBoundaries"
            :viewport="landscapeViewport"
          >
          </vue-croppie>
          <vue-croppie
            v-else
            class="picture-upload__cropper"
            ref="croppieRef"
            :enableOrientation="true"
            :enableResize="false"
            :enforceBoundary="false"
            :boundary="{ width: 270, height: 480 }"
            :viewport="{ width: 270, height: 480, type: 'square' }"
          >
          </vue-croppie>
        </div>

        <Button class="picture-upload__set-btn" @click="setImage">
          {{ $t("pages.editor.uploadPhoto") }}
        </Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
import Button from "@/components/Button";
import "vue-advanced-cropper/dist/style.css";

export default {
  components: {
    Modal,
    Button
  },
  props: {
    orientation: {
      type: String,
      default: "0"
    }
  },
  data: () => ({
    image: {
      src: null,
      name: null
    },
    landscapeOptions: {
      type: "base64",
      size: { width: 1920, height: 1080 }
    },
    portraitOptions: {
      type: "base64",
      size: { width: 1080, height: 1920 }
    },
    showModal: false,
    feedback: ""
  }),
  computed: {
    isMobile() {
      if (window.innerWidth < 780) {
        return true;
      }
      return false;
    },
    landscapeBoundaries() {
      if (this.isMobile) {
        return { width: 295, height: 165 };
      }

      return { width: 640, height: 360 };
    },
    landscapeViewport() {
      if (this.isMobile) {
        return { width: 295, height: 165, type: "square" };
      }

      return { width: 640, height: 360, type: "square" };
    },
    isSlidePreview() {
      return this.$route.name === "SlidePreview";
    }
  },
  methods: {
    reset() {
      this.image = {
        src: null,
        name: null
      };
      this.$refs.file.value = null;
    },
    loadImage(e) {
      const maxFileSize = 1024 * 1024 * 10; // 10MB
      const allowedExtensions = ["png", "jpg", "jpeg"];

      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      const extension = files[0].name.split(".").pop();
      const size = files[0].size;

      if (allowedExtensions.includes(extension.toLowerCase())) {
        this.feedback = "";

        if (size < maxFileSize) {
          this.feedback = "";
          let reader = new FileReader();
          this.showModal = true;

          reader.onload = e => {
            this.$refs.croppieRef.bind({
              url: e.target.result
            });
            this.image.name = files[0].name.replace(/\..+$/, "");
          };

          reader.readAsDataURL(files[0]);
        } else {
          this.feedback = this.$t("pages.editor.imageMaxFileSize");
        }
      } else {
        this.feedback = this.$t("pages.editor.imageFileType");
      }
    },
    setImage() {
      let options =
        this.orientation === "0" ? this.landscapeOptions : this.portraitOptions;
      this.$refs.croppieRef.result(options, output => {
        this.image.src = this.croppieImage = output;
        this.$emit("on-image-set", this.image);
        this.showModal = false;
        this.reset();
      });
    },
    onClose() {
      this.showModal = false;
      this.reset();
    }
  },
  destroyed() {
    if (this.image.src) {
      URL.revokeObjectURL(this.image.src);
    }
  }
};
</script>

<style lang="scss">
.picture-upload {
  &__content {
    display: flex;
    flex-direction: column;
  }

  &__feedback {
    @apply text-ls-red mt-2 text-xs font-semibold;
  }

  &-btn {
    @apply px-3 py-2 cursor-pointer bg-white border-ls-gray-400 border rounded-lg text-xs font-semibold;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    display: inline-block;
  }

  &__set-btn {
    @apply mt-4;
  }

  .cr-resizer {
    @apply border border-ls-gray-300;
  }

  .cr-boundary {
    @apply border border-ls-gray-300;
  }

  input[type="file"] {
    display: none;
  }
}
</style>
