<template>
  <div class="editor-webview">
    <div class="editor-webview-bar" v-if="!isSlidePreview">
      <div class="editor-webview-bar__element">
        <div class="editor-webview-bar__element-label">
          URL
        </div>
        <input class="editor-webview-bar__input" v-model="url" />
        <Button
          :disabled="disableSaveButton"
          class="editor-webview-bar__button"
          @click="setWebviewUrl"
        >
          {{ $t("pages.editor.webview.addUrl") }}
        </Button>
        <div class="editor-webview-bar__feedback">
          {{ feedback }}
        </div>
        <div class="editor-webview-bar__feedback" v-if="showEmptyUrlError">
          {{ $t("pages.editor.webview.feedback.empty") }}
        </div>
        <a :href="url" target="_blank" v-if="slide.url">
          <Button class="editor-webview-bar__button" theme="secondary">
            Preview Web Page
          </Button>
        </a>
      </div>
    </div>
    <div class="editor-webview-content" v-if="slide.url">
      <div v-if="showUrlPreview">
        <div
          class="editor-webview-content__iframe-wrapper editor-webview-content__iframe-wrapper--horizontal"
          v-if="orientation === '0'"
        >
          <iframe
            ref="iFrame"
            class="editor-webview-content__iframe editor-webview-content__iframe--horizontal"
            :src="slide.url.landscape"
          />
        </div>
        <div
          class="editor-webview-content__iframe-wrapper editor-webview-content__iframe-wrapper--vertical"
          v-if="orientation === '1'"
        >
          <iframe
            ref="iFrame"
            class="editor-webview-content__iframe editor-webview-content__iframe--vertical"
            :src="slide.url.landscape"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";
import isURL from "validator/es/lib/isURL";
import { allowedUrls } from "@/schema/allowedUrls";

export default {
  components: {
    Button
  },
  props: {
    slide: {
      type: Object,
      default: () => {}
    },
    showEmptyUrlError: {
      type: Boolean,
      default: false
    },
    orientation: {
      type: String,
      default: "0"
    }
  },
  data: () => ({
    url: "",
    feedback: "",
    disableSaveButton: false,
    showUrlPreview: false
  }),
  computed: {
    isSlidePreview() {
      return this.$route.name === "SlidePreview";
    }
  },
  watch: {
    url() {
      if (this.slide.url) {
        this.disableSaveButton = this.slide.url.landscape === this.url;
      }
    },
    slide: {
      handler() {
        if (this.slide.url) {
          this.url = this.slide.url.landscape;
          this.checkUrl();
        }
      },
      deep: true
    }
  },
  mounted() {
    if (this.slide.url) {
      this.url = this.slide.url.landscape;
      this.checkUrl();
    }
  },
  methods: {
    checkUrl() {
      this.showUrlPreview = allowedUrls.some(url => this.url.includes(url));
    },
    async setWebviewUrl() {
      this.checkUrl();

      let options = {
        require_protocol: true,
        protocols: ["https"]
      };

      if (!isURL(this.url, options)) {
        this.feedback = this.$t("pages.editor.webview.feedback.invalidUrl");
        return;
      }

      this.$emit("adjusted-url");

      this.feedback = "";
      if (this.slide.url) {
        this.slide.url.landscape = this.url;
        this.slide.url.portrait = this.url;
        this.disableSaveButton = true;
      } else {
        let url = {
          landscape: this.url,
          portrait: this.url
        };

        this.slide.url = url;
        this.disableSaveButton = true;
      }
    }
  }
};
</script>

<style lang="scss">
.editor-webview-bar {
  position: fixed;
  left: 0;
  top: 53px;
  padding: 16px 12px;
  bottom: 0;
  width: 280px;
  background-color: #fff;
  z-index: 1;
  overflow-y: auto;

  &__element-label {
    @apply text-xs font-semibold mb-2;
  }

  &__input {
    @apply bg-ls-gray-100 rounded-lg px-2 py-3 w-full;
    font-size: 14px;
    line-height: 20px;
  }

  &__button {
    @apply w-full mt-3;
  }

  &__feedback {
    @apply mt-2 text-ls-red text-sm;
  }
}

.editor-webview-content {
  height: calc(100vh - 53px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__iframe-wrapper--horizontal {
    width: 768px;
    height: 432px;
  }

  &__iframe-wrapper--vertical {
    width: 324px;
    height: 576px;
  }

  &__iframe {
    &--horizontal {
      width: 1920px;
      height: 1080px;
      transform: scale(0.4);
      transform-origin: 0 0;
    }

    &--vertical {
      width: 1080px;
      height: 1920px;
      transform: scale(0.3);
      transform-origin: 0 0;
    }
  }
}
</style>
